<template>
  <div>
    <div v-if="!mobileView" id="mySidenav" class="sidenav " style="background-color: #5b6066;" v-click-outside="hoverOut">
      <div v-click-outside="hoverOut">
        <div
          class="d_icon_box items-center hover:bg-primary"
          v-for="item in routesList"
          :key="item.path"
          @click="routeTo(item.name)"  :class="item.meta.selected ? 'border-l-4  shadow-sm border-white transition delay-150 text-white' : ''"
        >
          <div class="d_icon_child" >
            <div class="p-3 rounded ">
              <component :is="item.meta.routeIcon" v-bind="item.meta.routIconSize"></component>
            </div>
          </div>
          <span class="d_items_text text-white ">{{ item.meta.title }}</span>
        </div>
      </div>
    </div>
    <div v-else class="sv_sidenav_box">
      <div id="sv_smySidenav" class="sv_sidenav" style="background-color: #5b6066;" v-click-outside="hoverOut">
        <div v-click-outside="hoverOut"
          class="d_icon_box items-center hover:bg-primary"
          v-for="item in routesList"
          :key="item.path"
          @click="routeTo(item.name)"  :class="item.meta.selected ? 'border-l-4 shadow-sm border-white transition delay-150 text-white' : ''"
        >
          <div class="d_icon_child" >
            <div class="p-3 rounded ">
              <component :is="item.meta.routeIcon" v-bind="item.meta.routIconSize"></component>
            </div>
          </div>
          <span class="d_items_text text-white">{{ item.meta.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {removeCookies} from '@/utils/cookies'
import { getCookies } from "@/utils/cookies";
import dashboardSvg from '@/assets/images/svg/dashboardSvg.vue'
import userSidebar from '@/assets/images/svg/userSidebar.vue'
import listSideBar from '@/assets/images/svg/listSideBar.vue'
import customerSide from '@/assets/images/svg/customerSide.vue'
import leadSide from '@/assets/images/svg/leadSide.vue'
import orgSideIcon from '@/assets/images/svg/orgSideIcon.vue';
import SupportSvg from '@/assets/images/svg/supportSvg.vue';
import ActivityIcon from '@/assets/images/svg/activityIcon.vue';
import SettingSvg from '@/assets/images/svg/settingSvg.vue';
import colors from '@/assets/colors.json'
import router from '@/router/index.js'
export default {
  components: {
    ActivityIcon,
    SupportSvg,
    SettingSvg,
    orgSideIcon,
    userSidebar,
    listSideBar,
    leadSide,
    customerSide,
    dashboardSvg,
  },
  name: "leftNavbar",
  props: ["routesList"],
  data() {
    return {
      isDraweOpen: false,
      userDetail: {},
      mobileView: false,
      toutInfo: null,
      drawerOpen: false,
      extraSmall: false,
      drawerIsOpen: false,
      showMenuContinue: true
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  watch: {
    routesList: {
      handler () {
        this.setNavigation(this.$route.meta.loadPage)
      }
    }
  },
  mounted() {
    this.$root.$on('openDialog', (data) => {
      console.log('click click', data)
      if (data) {
        if (this.isDraweOpen) {
          this.hoverOut()
        } else {
          this.hoverIs()
        }
      } else {
        this.hoverOut()
      }
    })
    this.resizeWindowHandler();
    if (getCookies("lockDrawer") === "draweLocked" && !this.mobileView) {
      this.drawerIsOpen = true;
    } else {
      this.drawerIsOpen = false;
    }
    router.afterEach(() => {
      setTimeout(() => {
        this.setNavigation(this.$route.meta.loadPage)
      }, 100);
    })
    let userData = localStorage.getItem("employeeData1");
    this.userDetail = JSON.parse(userData);
  },
  methods: {
    hoverIs () {
      this.isDraweOpen = true
      if (!this.mobileView) {
        document.getElementById("mySidenav").style.minWidth = "250px";
        document.getElementById("mySidenav").style.maxWidth = "250px";
        document.getElementById("main_content_box").style.paddingLeft = "266px";
        document.getElementById("mySidenav").style.boxShadow = "2px 0px 23px -6px rgba(128,128,128,0.52)";
      } else {
        document.getElementById("sv_smySidenav").style.minWidth = "250px";
        document.getElementById("sv_smySidenav").style.maxWidth = "250px";
        document.getElementById("sv_smySidenav").style.boxShadow = "2px 0px 23px -6px rgba(128,128,128,0.52)";
      }
    },
    hoverOut () {
      this.isDraweOpen = false
      if (!this.mobileView) {
        document.getElementById("mySidenav").style.minWidth = "70px";
        document.getElementById("mySidenav").style.maxWidth = "70px";
        document.getElementById("main_content_box").style.paddingLeft = "82px";
        document.getElementById("mySidenav").style.boxShadow = null;
      } else {
        document.getElementById("sv_smySidenav").style.minWidth = "0px";
        document.getElementById("sv_smySidenav").style.maxWidth = "0px";
        document.getElementById("sv_smySidenav").style.boxShadow = null;
      }
    },
    LogOut () {
      removeCookies('authToken')
      localStorage.clear()
      this.$store.dispatch('GetEmployeePermission', true)
      this.$router.push({name: 'login'})
      location.reload()
    },
    setNavigation (value) {
      for (let index = 0; index < this.routesList.length; index++) {
        if (value === this.routesList[index].meta.loadPage) {
          this.routesList[index].meta.selected = true;
          this.routesList[index].meta.routIconSize.color = colors.white;
        } else {
          this.routesList[index].meta.selected = false;
          this.routesList[index].meta.routIconSize.color = colors.white;
        }
      }
    },
    routeTo(path) {
      console.log('path', path)
      console.log('check11', this.$route)
      console.log('$routesList', this.routesList)
      this.$router.push({ name: path })
      for (let index = 0; index < this.routesList.length; index++) {
        if (this.$route.meta.loadPage === this.routesList[index].meta.loadPage) {
          this.routesList[index].meta.selected = true;
          this.routesList[index].meta.routIconSize.color = colors.white;
        } else {
          this.routesList[index].meta.selected = false;
          this.routesList[index].meta.routIconSize.color = colors.white;
        }
      }
    },
    closeDrawer() {
      this.showMenuContinue = false
        // console.log('check 11', getCookies("lockDrawer"))
      document.getElementById("overlay").style.display = "none";
      if (getCookies("lockDrawer") === "draweUnLocked" || getCookies("lockDrawer") === null) {
        document.getElementById("myTopnav").style.left = "70px";
        document.getElementById("mySidenav").style.minWidth = "70px";
        this.drawerIsOpen = false;
      }
      setTimeout(() => {
        this.showMenuContinue = true
      }, 600);
    },
    openDrawer() {
      // console.log('check 10', this.showMenuContinue)
      setTimeout(() => {
        if (this.showMenuContinue) {
          document.getElementById("mySidenav").style.minWidth = "260px";
          document.getElementById("menu_items_box").style.left = "275px";
          if (getCookies("lockDrawer") === "draweUnLocked") {
            // document.getElementById('myTopnav').style.left = '248px'
            this.showDashboadMenus = false;
            this.showHistory = false;
            this.showAddFavorite = false;
            this.showAvtarMenu = false;
            this.$root.$emit('closeCardPopUp', true)
          }
          this.drawerIsOpen = true;
        }
      }, 400);
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
    },
  },
};
</script>
<style scoped>
</style>
