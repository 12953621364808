import Vue from 'vue'
import Router from 'vue-router'
import { Routing } from '@/View/Admin/router/index.js'

Vue.use(Router)

export const constantRouterMap = [{
        path: '/',
        hidden: true,
        redirect: '/login'
    },
    { // Login Page
        path: '/login',
        component: () =>
            import ('@/View/login/specificCompanyLogin.vue'),
        hidden: true,
        name: 'login',
        meta: {
            title: 'login',
            selected: false,
            permissionList: []
        }
    },
    { // Dashboard object start
        path: '/',
        component: () => import ('@/View/Admin/Layout/layout.vue'),
        hidden: true,
        name: 'dashboard',
        meta: {
            title: 'dashboard',
            selected: false,
            permissionList: []
        }
    },
    Routing,
]
export default new Router({
    routes: constantRouterMap,
    // FOR REMOVE HASTAG
    mode: 'history'
})