import axios from 'axios'
import store from '@/store'
import * as config from '../../../config.js'
/**
 * init class API
 * @param opt
 * @returns {AdminApi}
 * @constructor
 */

function AdminApi (opt) {
  if (!(this instanceof AdminApi)) {
    return new AdminApi(opt)
  }
  opt = opt || {}
  this.classVersion = '1.0.0'
  this.api = axios.create({
    baseURL: config.API_ROOT,
    timeout: 9000000
  })
  this.api.interceptors.request.use(request => {
    store.dispatch('SetIsApiRunning', true)
    return request
  })
  this.api.interceptors.response.use(response => {
    store.dispatch('SetIsApiRunning', false)
    return response
  },
  error => {
    store.dispatch('SetIsApiRunning', false)
    return error
  })
}
/**
 * init class API
 * @param opt
 * @returns {AdminApi}
 * @constructor
 */

AdminApi.prototype._requestAuthPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  })
}
AdminApi.prototype._requestDataPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
AdminApi.prototype._requestDataPostFileAttachment = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType,
    responseType: 'blob'
  }
  return this.api.post(url, data, headers).then((response) => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
AdminApi.prototype._requestDataGet = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType
  }
  var param = ''
  if (data !== '') {
    param = '' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
AdminApi.prototype._requestFileDataGet = function (url, contentType, data, callback) {
  var headers = {
    responseType: 'arraybuffer'
  }
  var param = ''
  if (data !== '') {
    param = '/' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}


AdminApi.prototype.StaffSearch = async function (data, callback) {
  const requestUrl = 'api/v1/staff/search'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.CheckNumberExistStaff = async function (data, callback) {
  const requestUrl = 'api/v1/staff/checkEmailORPhoneExist'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetStaffList = async function (data, callback) {
  const requestUrl = 'api/v1/staff/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetStaffDetail = async function (data, callback) {
  const requestUrl = 'api/v1/staff/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.UpdateStaffApi = async function (data, callback) {
  const requestUrl = 'api/v1/staff/update'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.NewStaffSave = async function (data, callback) {
  const requestUrl = 'api/v1/staff/save'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.saveNewListAPi = async function (data, callback) {
  const requestUrl = 'api/v1/contact/list/create'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.CheckListNameExist = async function (data, callback) {
  const requestUrl = 'api/v1/contact/list/checklistExits'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetListList = async function (data, callback) {
  const requestUrl = 'api/v1/contact/list/files'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetSublistFromList = async function (data, callback) {
  const requestUrl = 'api/v1/contact/list/files/data'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.TwilioConfiguration = async function (data, callback) {
  const requestUrl = 'api/v1/setting/twilio/configSave'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.TwilioConfigurationList = async function (data, callback) {
  const requestUrl = 'api/v1/setting/twilio/configList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SaveNewCampaign = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/create'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.UpdateNewCampaign = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/update '
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.CampaignList = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.DeleteCampaign = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/delete'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.UpdateLimit = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/changeLimit'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SendLeadToCRMApi = async function (data, callback) {
  const requestUrl = 'api/v1/lead/sendLeadToCRM'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetTwilioConfDetail = async function (data, callback) {
  const requestUrl = 'api/v1/setting/twilio/configDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.MsgLimitUpdate = async function (data, callback) {
  const requestUrl = 'api/v1/setting/msgLimit/update'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GlobalSearch = async function (data, callback) {
  const requestUrl = 'api/v1/lead/globalSearch'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetLeadListing = async function (data, callback) {
  const requestUrl = 'api/v1/lead/replies'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetLeadDetails = async function (data, callback) {
  const requestUrl = 'api/v1/lead/details'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetLeadMessages = async function (data, callback) {
  const requestUrl = 'api/v1/lead/messages'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SendSmsLeadScreen = async function (data, callback) {
  const requestUrl = 'api/v1/sms/send'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.StartCampaign = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/start'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.StopCampaign = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/stop'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.CheckStartTimeAlert = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/checkStartTimeAlert'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.UpdateAssigneeinDetail = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/updateStaffAssignee'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

// GET


AdminApi.prototype.GetMessageLimit  = async function (data, callback) {
  const requestUrl = 'api/v1/setting/msgLimit/get'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetEmailConfDetail  = async function (data, callback) {
  const requestUrl = 'api/v1/superadmin/setting/emailConfiguration/detail'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getListDatabase  = async function (data, callback) {
  const requestUrl = 'api/v1/contact/list/contactFields'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetTimeZones = async function (data, callback) {
  const requestUrl = 'api/v1/preference/timeZones'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetCampaignDetail = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/detail'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
AdminApi.prototype.StartCampaignAlert = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/startAlert'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetPrefrenceTag = async function (data, callback) {
  const requestUrl = 'api/v1/preference/tags'
  const contentType = 'application/json'
  return this._requestDataGet(requestUrl, contentType, data, callback)
}

AdminApi.prototype.PlivoConfigurationList = async function (data, callback) {
  const requestUrl = 'api/v1/setting/plivo/configList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.PlivoConfigurationDetail = async function (data, callback) {
  const requestUrl = 'api/v1/setting/plivo/configDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.PlivoConfigurationSave = async function (data, callback) {
  const requestUrl = 'api/v1/setting/plivo/configSave'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.ActiveCommunicationNumbers = async function (data, callback) {
  const requestUrl = 'api/v1/setting/numbers'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}

AdminApi.prototype.SendLeadSMSViaPlivo = async function (data, callback) {
  const requestUrl = 'api/v1/sms/pli/send'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SmsTemplateAddEdit = async function (data, callback) {
  const requestUrl = 'api/v1/setting/smsTemplate/save'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SmsTemplateDetail = async function (data, callback) {
  const requestUrl = 'api/v1/setting/smsTemplate/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SmsTemplateList = async function (data, callback) {
  const requestUrl = 'api/v1/setting/smsTemplate/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.smsTemplateReOrder = async function (data, callback) {
  const requestUrl = 'api/v1/setting/smsTemplate/reOrder'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SmsTemplateDelete = async function (data, callback) {
  const requestUrl = 'api/v1/setting/smsTemplate/delete'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.SmsTemplateActiveList = async function (data, callback) {
  const requestUrl = 'api/v1/setting/smsTemplate/activeList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.MergeSmsTemplate = async function (data, callback) {
  const requestUrl = 'api/v1/setting/smsTemplate/mergeSmsTemplate'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetDripMessageSentLog = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/sentLogList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetSentDripContactDetail = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/sentLogDetail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetContactSentDripMessageList = async function (data, callback) {
  const requestUrl = 'api/v1/campaign/sentMsgLogList'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetcampaignReqList = async function (data, callback) {
  const requestUrl = 'api/v1/lead/campaignes'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
export default AdminApi
