import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import './assets/globals.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'quill-mention/dist/quill.mention.min.css'
import router from './router'
import store from './store'
import './filter/filter.js'
import './permission.js'
Vue.config.productionTip = false
import VueRx from "vue-rx"; Vue.use(VueRx);
import VuejsClipper from 'vuejs-clipper/dist/vuejs-clipper.umd'
Vue.use(VuejsClipper)
Vue.config.productionTip = false
Vue.directive('click-outside',{
  bind: function (el, binding, vnode) {
      el.eventSetDrag = function () {
          el.setAttribute('data-dragging', 'yes');
      }
      el.eventClearDrag = function () {
          el.removeAttribute('data-dragging');
      }
      el.eventOnClick = function (event) {
          var dragging = el.getAttribute('data-dragging');
          // Check that the click was outside the el and its children, and wasn't a drag
          if (!(el == event.target || el.contains(event.target)) && !dragging) {
              // call method provided in attribute value
              vnode.context[binding.expression](event);
          }
      };
      document.addEventListener('touchstart', el.eventClearDrag);
      document.addEventListener('touchmove', el.eventSetDrag);
      document.addEventListener('click', el.eventOnClick);
      document.addEventListener('touchend', el.eventOnClick);
  }, unbind: function (el) {
      document.removeEventListener('touchstart', el.eventClearDrag);
      document.removeEventListener('touchmove', el.eventSetDrag);
      document.removeEventListener('click', el.eventOnClick);
      document.removeEventListener('touchend', el.eventOnClick);
      el.removeAttribute('data-dragging');
  },
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')