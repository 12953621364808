<template>
    <div style="width: 100%" class="flex items-center relative cursor-pointer">
      <div class="dashboard_menus">
        <span @click="showSearch()" ><i style="font-size:20px" class="fa fa-search text-gray2"></i></span>
      </div>
      <div class="input_box cursor-pointer">
        <input :style="!showGlobalSearch ? `background: ${color123}` : `background: ${color123}`"
          autocomplete="off" class="cursor-pointer"
          type="text"
          name=""
          @blur="blurAction"
          @focus="showGlobalSearch = true"
          placeholder="Type to search..."
          id="wpsearchInput"
          @input="getListDebaunce"
          v-model="searchValue">
      </div>
      <div v-if="showGlobalSearch" class="search_result_box absolute z-50 top-12 card" :style="extraSmall ? 'width: auto' : 'width: 600px !important;'">
        <div class="  bg-white p-4 w-full card">
          <div v-if="searchValue.length < 2" wrap>
            <div class="mx-0 px-0">
              <p class="subtitle-1 mb-0 ">Please enter 2 characters or more.</p>
            </div>
          </div>
          <div v-if="searchingApi" wrap>
            <div  class="mx-0 px-0">
              <p class="subtitle-1 mb-0">Searching...</p>
            </div>
          </div>
          <div v-if="searchingApi === false && searchArray.length === 0 && searchValue.length >= 2" wrap>
            <div class="mx-0 px-0">
              <p class="subtitle-1 mb-0">No Record Found.</p>
            </div>
          </div>
          <p v-if="searchArray.length > 0" class="text-gray3 font-semibold heading-7 mb-1">Total {{totalWP > 0 ? totalWP : searchArray.length}} Record{{searchArray.length > 1 ? 's' : ''}} Found</p>
          <div id="SearchDataScroll" class="text-text2" @scroll="scrollDownToLoadMoreData" :style="`max-height: `+cardHeight+`px;overflow: auto;`">
            <div wrap v-for="(data, index) in searchArray" :key="index" class="result-row">
              <div class="mx-0 px-0 c-pointer">
                <div class="divider" v-if="index > 0"></div>
              </div>
              <div @click.stop="openWorkPackage(data)" class="cursor-pointer py-1" >
                <!-- <span class="text-gray4 heading-5 "><p class="mb-0" >Campaign Start Date:</p></span> -->
                <div class="flex items-center justify-between" @click.stop.prevent="openWorkPackage(data)">
                  <span class="text-primary heading-5" >{{data.campaignName}}</span>
                  <span @click.stop="openWorkPackageInNewTab(data)" class="pr-2 text-primary heading-5 transition"><i class="fa-solid fa-arrow-up-right-from-square"></i></span>
                </div>
                <div class="flex items-center" v-if="data.firstName !== '' || data.firstName !== ''" @click.stop.prevent="openWorkPackage(data)">
                  <i class="fas fa-user text-gray4 pr-2 heading-6"></i>
                  <span class="text-gray4 heading-6 font-semibold" ><span v-html="matchFind(data.firstName)"></span> <span v-html="matchFind(data.lastName)"></span></span>
                </div>
                <div class="flex items-center" v-if="data.phoneNumber !== ''" @click.stop.prevent="openWorkPackage(data)">
                  <i class="fas fa-phone text-gray4 pr-2 heading-6"></i>
                  <span class="text-gray4 heading-6 font-semibold" ><span v-html="matchFind(data.phoneNumber)"></span></span>
                </div>
              </div>
              <!-- <div @click="openWorkPackage(data)" class="cursor-pointer" >
                <div class="flex mb-1">
                    <p class="mb-0 wp-search-title cursor-pointer text-text1 " id="texthighlight" v-html="matchFind(data.workPackageTitle)" @click.stop.prevent="openWorkPackage(data)"></p>
                  <div class="">
                    <p class="mb-0" @click.stop.prevent="openWorkPackageInNewTab(data)">
                      <span class="mr-2 mb-0 cursor-pointer text-primary" size="16"><i class="fas fa-external-link-alt"></i></span>
                    </p>
                  </div>
                </div>
                <div  class=" cursor-pointer mb-1" >
                  <div v-for="(note, index1) in data.combineData" :key="index1">
                  <p class="mb-1 heading-6 wp-search-title" v-if="note.WpCommnetAndDescription != ''" id="texthighlight" v-html="matchFind(note.WpCommnetAndDescription)"></p>
                </div>
                <div class="flex">
                  <p class="mb-0  project-title" id="texthighlight" v-html="matchFind(data.projectName)"></p>
                  <p class="mb-0 text-gray3 wp-number">
                    #{{data.workPackageNumber}}</p>
                  <p class="mb-0 text-right wp-status" :style="`color:` + data.WorkPackageStatusColor">{{data.WorkPackageStatus}}</p>
                </div>
                </div>
              </div> -->
            </div>
            <!-- <v-progress-circular
              style="left: 50%;margin-top: 13px;"
              v-if="isLoader && searchValue.length > 2"
              :indeterminate="isLoader"
              class="mb-4"
              color="primary"
            ></v-progress-circular> -->
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  data () {
    return {
      color123: 'hsla(0, 0%, 100%, .001)',
      cardHeight: 500,
      getListDebaunce: null,
      menu: '',
      open: true,
      close: true,
      showGlobalSearch: false,
      searchValue: '',
      isMobile: false,
      searchingApi: false,
      totalWP: 0,
      isLoader: true,
      filterObject: {
        offset: 0,
        limit: 20,
        orderByColumn: '',
        sortOrder: ''
      },
      searchArray: [],
      currentPage: 1,
      isloadMore: true
    }
  },
  mixins: [deboucneMixin],
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.resizeWindowHandler();
    this.getListDebaunce = this.debounce(function () {
      console.log('searchValue', this.searchValue);
      this.menu = true
      this.searchArray = []
      this.totalWP = 0
      this.filterObject.offset = 0
      this.isloadMore = true
      // console.log('this.isloadMore----1', this.isloadMore)
      if (this.searchValue !== null) {
        if (this.searchValue.length >= 2) {
          this.searchWorkPakcage()
        }
      } else {
        this.searchValue = ''
      }
    }, 500)
  },
  watch: {
    showGlobalSearch: {
      handler () {
        if (!this.showGlobalSearch) {
          this.searchValue = ''
          this.searchArray = []
          this.color123 = 'hsla(0, 0%, 100%, .001)'
        } else {
          this.color123 = '#FFF'
        }
        this.$emit('showGlobalSearch1', this.showGlobalSearch)
      }
    }
  },
  methods: {
    blurAction () {
      setTimeout(() => {
        this.showGlobalSearch = false
      }, 500);
    },
    showSearch () {
      setTimeout(() => {
        // if (this.extraSmall) {
        //   document.getElementById('wpsearchInput').style.display = 'block'
        // }
        document.getElementById('wpsearchInput').focus()
      }, 100)
    },
    clearInput () {
      this.searchValue = ''
      this.searchArray = []
    },
    clearContentClick (event) {
      console.log(event)
      this.searchValue = ''
      this.searchArray = []
    },
    searchWorkPakcage () {
      this.isLoader = true
      if (this.filterObject.offset === 0) {
        this.searchingApi = true
      }
      ADMINAPI.GlobalSearch(
        this.filterObject.offset,
        this.filterObject.limit,
        this.searchValue,
        response => {
          this.searchingApi = false
          this.isLoader = false
          let data = response.Data.tableRow === null ? [] : response.Data.tableRow
          for (let index = 0; index < data.length; index++) {
            this.searchArray.push(data[index])
          }
          this.totalWP = response.Data.count
          if (this.searchArray.length < this.filterObject.offset) {
            this.isloadMore = false
            this.filterObject.offset = 0
            this.filterObject.limit = 20
          } else {
            this.isloadMore = true
          }
        },
        error => {
          console.log(error)
        }
      )
    },
    scrollDownToLoadMoreData () {
      var sh = document.getElementById('SearchDataScroll').scrollHeight
      var st = document.getElementById('SearchDataScroll').scrollTop
      var oh = document.getElementById('SearchDataScroll').offsetHeight
      console.log('sh', sh)
      console.log('st', st)
      console.log('oh', oh)
      console.log('sh - st - oh + 1  ----', sh - st - oh + 1)
      if (sh - st - oh + 1 === 1) {
        this.isLoader = true
        this.currentPage++
        this.filterObject.offset += 20
        this.searchWorkPakcage()
      }
      // if (this.isloadMore === true) {
      //   if (emt.scrollTop + emt.clientHeight >= emt.scrollHeight) {
      //     this.isLoader = true
      //     this.currentPage++
      //     this.filterObject.offset = (this.currentPage - 1) * this.filterObject.limit
      //     this.searchWorkPakcage()
      //   }
      // }
    },
    openWorkPackage (wpDetail) {
      this.close = true
      this.open = true
      this.menu = false
      this.searchValue = ''
      this.searchArray = []
      this.$router.push({name: 'Leads'})
      setTimeout(() => {
        this.$router.push({name: 'leadDetail', params: {leadId: wpDetail.leadId}, query: {campaignMasterId: wpDetail.campaignMasterId}})
      }, 120);
    },
    openWorkPackageInNewTab (wpDetail) {
      let routeData1 = this.$router.resolve({name: 'leadDetail', params: {leadId: wpDetail.leadId}, query: {campaignMasterId: wpDetail.campaignMasterId}})
      window.open(routeData1.href, '_blank')
    },
    onResize () {
      this.cardHeight = window.innerHeight - 200
    },
    resizeWindowHandler() {
      if (window.innerWidth < 600) {
        this.extraSmall = true;
      } else {
        this.extraSmall = false;
      }
      this.searchWidth = window.innerWidth - 700
    },
    matchFind (str) {
      if (this.searchValue === null || this.searchValue === undefined || this.searchValue === ' ') {
        return
      }
      let searchStrArray = this.searchValue.split(' ')
      let replceString = str.trim()
      for (let i = 0; i < searchStrArray.length; i++) {
        if (searchStrArray[i].trim() !== '' && searchStrArray[i].trim() !== 'em') {
          // ('\\b' + searchStrArray[i] + '\\b', 'gi')
          // (searchStrArray[i], 'gi')
          replceString = replceString.replace(new RegExp(searchStrArray[i], 'gi'), match => {
            return '<em>' + match + '</em>'
          })
        }
      }
      return replceString
    }
    // matchFind (str) {
    //   if (this.searchValue === null || this.searchValue === undefined || this.searchValue === ' ') {
    //     return
    //   }
    //   let searchStrArray = this.searchValue.split(' ')
    //   let replceString = str.trim()
    //   for (let i = 0; i < searchStrArray.length; i++) {
    //     if (searchStrArray[i].trim() !== '') {
    //       // replceString = replceString.match('/' + searchStrArray[i] + '/gi')
    //       // return `<span style="background-color: yellow">` + replceString + `</span>`
    //       replceString = replceString.replace(new RegExp('\\b' + searchStrArray[i] + '\\b', 'gi'), matching => {
    //         return '<span style="background-color:yellow;color:black;">' + matching + '</span>'
    //       })
    //       // replceString = replceString.replace(new RegExp(searchStrArray[i], 'gi'), match => {
    //       //   return `<span style="background-color: yellow">` + match + `</span>`
    //       // })
    //     }
    //   }
    //   return replceString
    // }
  }
}
</script>
<style scoped>
.search_result_box {
    position: absolute;
    z-index: 100 !important;
}
.search_result_card {
    width: 1200px;
}
.dashboard_menus:hover {
    cursor: pointer;
}
.dashboard_menus {
    display: flex;
    justify-content: center;
}
.input_box {
    height: 100%;
    width: 100%;
}
.input_box input {
    border-radius: 8px;
    width: 100%;
    height: 48px;
    padding: 8px;
}
.input_box input:focus {
    outline: 1px solid #ecececb7;
}
.search_bar {
  size: 50px;
  border: 1px solid #BDBDBD;
  display: flex;
  border-radius: 3px;
  height: 46px;
  width: 50px;
  padding: 3px;
  position: relative;
  transition:  500ms cubic-bezier(0.18, 0.89, 0.32, 1.15);
  overflow: hidden;
}
.search_bar:focus-within {
  width: 100%;
}
.search_bar:focus-within .search-bar_submit {
  background: rgb(237, 103, 54);
  color: white;
  display: none;
}
.search_bar:focus-within .input_clear {
  display: block;
}
.search_bar:focus-within .search-bar_submit:hover {
  outline: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
}
.search_bar:focus-within .search-bar_input {
  opacity: 1;
  cursor: initial;
  width: calc(85%);
}
.search-bar_input {
  flex-grow: 1;
  font-size: 1.2rem;
  padding: 0 0.5rem;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  line-height: 57px;
  opacity: 0;
  cursor: pointer;
}
.search-bar_input:focus {
  outline: 0;
}
.search-bar_submit {
  font-size: 1.2rem;
  cursor: pointer;
  border: 0;
  background: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: auto;
  transition: background 150ms ease-in-out;
}
.input_clear {
  font-size: 1.5rem;
  cursor: pointer;
  border: 0;
  background: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: auto;
  transition: background 150ms ease-in-out;
}
.input_clear {
  display: none;
}
.project-title {
  width: calc(100% - 230px);
}
.wp-number {
  width: 50px;
}
.wp-status {
  width: 150px;
}
.wp-search-title {
  width: calc(100% - 20px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.result-row:hover {
  background-color: #fafafa;
}
.highlight {
  background-color: yellow;
}
</style>
